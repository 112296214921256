import {Injectable} from '@angular/core';
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";

@Injectable({
    providedIn: 'root'
})
export class FormService {
    public submitted: boolean = false;
    public usingSameAddress: boolean = true;
    public readOnly: boolean = false;

    public isInvalid(form: any, control?: string, rule?: string): boolean {
        if (!control) {
            return form.errors && (this.submitted || form.touched);
        }

        const input: any = form.get(control);
        const isInvalid: boolean = input?.invalid && (this.submitted || input?.dirty || input?.touched);

        if (isInvalid && rule) {
            return input.errors.hasOwnProperty(rule);
        }

        return isInvalid;
    }

    public formatDate(field: Date|undefined): Date|NgbDate|null
    {
        return  field ? new Date(field) : null;
    }

    public toggleSameAddress(form: any, primaryAddressLabel: string, secondaryAddressLabel: string): void {
        this.usingSameAddress = !this.usingSameAddress;

        if (this.usingSameAddress) {
            const primaryAddress = form.get(primaryAddressLabel)?.value;
            form.get(secondaryAddressLabel)?.patchValue(primaryAddress);
        }
    }

    public compareAddresses(primaryAddress: any, secondaryAddress: any): void {
        const address1 = {...primaryAddress, id: undefined};
        const address2 = {...secondaryAddress, id: undefined};

        this.usingSameAddress =  JSON.stringify(address1) === JSON.stringify(address2);
    }
}
